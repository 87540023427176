/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Seo from '../components/Seo';
import BrandGrid from '../components/BrandGrid/BrandGrid';
import Post from '../components/Blog/Post';

export const pageQuery = graphql`
  query NewsroomPageQuery {
    allWpPost(
      limit: 3
      sort: { fields: date, order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: "press-release" } } } }
      }
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1110)
              }
            }
          }
        }
        author {
          node {
            uri
            name
          }
        }
      }
    }
  }
`;

const Newsroom = ({ data }) => {
  const latestPosts = data.allWpPost.nodes;

  return (
    <>
      <Seo
        title="Newsroom · Medical Web Experts"
        description="Access news, public relations and company information related to Medical Web Experts."
        canonical="/newsroom/"
        schema={`
        {
          "@context": "http://schema.org/",
          "@type": "http://schema.org/ProfessionalService",
          "additionaltype": ["http://www.productontology.org/id/Web_design"],
          "name": "Medical Web Experts",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "5950 Sherry Ln, Ste 405A, Dallas, TX 75225, United States",
            "addressLocality": "Dallas",
            "addressRegion": "TX",
            "postalCode": "75225"
          },
          "image": "https://www.medicalwebexperts.com/images/mwe.png",
          "email": "info@medicalwebexperts.com",
          "telePhone": "+1-866-932-9944",
          "url": "https://www.medicalwebexperts.com/",
          "openingHours": "Mo,Tu,We,Th,Fr 09:00-17:00",
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
              "opens": "09:00",
              "closes": "17:00"
            }
          ],
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": "32.7816412",
            "longitude": "-96.7939741"
          },
          "priceRange": "$$",
          "sameAs": [
            "https://www.linkedin.com/company/medicalwebexperts",
            "https://www.youtube.com/user/MedicalWebExperts",
            "https://twitter.com/MedWebExperts/",
            "https://www.facebook.com/medical.website.design"
          ]
        }
        `}
      />
      <BrandGrid />

      {/* PAGE HEADER */}
      <Container className="my-4">
        <Row className="justify-content-center">
          <Col lg={10} xl={8}>
            <header className="d-flex flex-column justify-content-center align-items-center text-center">
              <h1 className="no-misc text-muted mb-1 fw-light page-header mb-1">
                Newsroom
              </h1>
              <h2 className="h1 h1--center mt-0">In the News</h2>
            </header>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col lg={3} className="px-4">
            <StaticImage
              src="../images/newsroom/logo-mhealth-times.png"
              height={60}
              alt="Mobile Apps"
            />
            <p className="text-muted mt-2 mb-0">
              <small>January 25, 2018</small>
            </p>
            <p>
              <a
                href="http://www.mobilehealthtimes.com/how-large-health-systems-are-still-failing-to-engage-patients-with-mobile-apps/"
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                How Large Health Systems Are Still Failing to Engage Patients
                with Mobile Apps
              </a>
            </p>
          </Col>
          <Col lg={3} className="px-4 border-start">
            <StaticImage
              src="../images/newsroom/logo-healthcare-guys.png"
              height={60}
              alt="mHealth App Market"
            />
            <p className="text-muted mt-2 mb-0">
              <small>September 19, 2019</small>
            </p>
            <p>
              <a
                href="https://www.healthcareguys.com/2019/09/19/the-mhealth-app-market-is-growing-rapidly-heres-why/"
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                The mHealth App Market is Growing Rapidly – Here’s Why
              </a>
            </p>
          </Col>
          <Col lg={3} className="px-4 border-start">
            <StaticImage
              src="../images/newsroom/logo-healthcare-guys.png"
              height={60}
              alt="HIPAA-Compliant Healthcare App"
            />
            <p className="text-muted mt-2 mb-0">
              <small>September 19, 2019</small>
            </p>
            <p>
              <a
                href="https://www.healthcareguys.com/2019/09/19/creating-a-hipaa-compliant-healthcare-app/"
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                Creating a HIPAA-Compliant Healthcare App
              </a>
            </p>
          </Col>
          <Col lg={3} className="px-4 border-start">
            <StaticImage
              src="../images/newsroom/logo-healthcare-business.png"
              height={60}
              alt="Rapid Expansion of the mHealth App Market"
            />
            <p className="text-muted mt-2 mb-0">
              <small>September 3, 2019</small>
            </p>
            <p>
              <a
                href="https://www.healthcarebusinesstoday.com/whats-driving-the-rapid-expansion-of-the-mhealth-app-market/"
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                What’s Driving the Rapid Expansion of the mHealth App Market?
              </a>
            </p>
          </Col>
        </Row>
        {latestPosts.length > 0 && (
          <>
            <hr className="my-5 my-md-7 no-border" />

            <h2 className="h1 h1--center text-center mb-5">Press Releases</h2>

            <Row className="justify-content-center">
              <Col lg={8}>
                {latestPosts.map((post) => {
                  const featuredImage = {
                    image: getImage(post.featuredImage?.node?.localFile),
                    alt: post.featuredImage?.node?.altText || ``,
                  };
                  return (
                    <React.Fragment key={post.uri}>
                      <div className="mb-4">
                        <Post
                          uri={post.uri}
                          title={post.title}
                          featuredImage={featuredImage}
                          date={post.date}
                          author={post.author.node}
                          excerpt={post.excerpt}
                        />
                      </div>
                      <hr className="no-border my-5 d-none d-md-block" />
                    </React.Fragment>
                  );
                })}
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default Newsroom;
